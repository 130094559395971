import React from "react"
import MainLayout from "../../components/MainLayout"
import SEO from "../../components/seo"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import ImagePicker from "../../components/ImagePicker"
import ProductDescription from "../../components/products/ProductDescription"
import IndexSection3 from "../../components/IndexSection3"

import Image1Large from "../../images/Orbitz20-w500.jpg"

const ProductLayout = styled.div`
  #products-layout {
    padding-top: 150px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 50% 50%;
    /* align-items: baseline; */

    @media (max-width: 1130px) {
      grid-template-columns: 100%;
      padding-top: 100px;
    }
  }
`

const Xs17PaCompactPage = () => {
  return (
    <>
      <SEO title='20" Orbital Floor Machine | Timberline Cleaning Equipment' />
      <Helmet>
        {/* <title>XS Compact Scrubber | Timberline Cleaning Equipment</title> */}
        <description>
          Thoroughly clean a variety of different floor surfaces with the ORBITZ
          20" Floor Machine. Pads are installed and removed without tools for
          easy application switching. Heavy-duty rolling wheels allow for ease
          of transport. Heavy-duty isolators reduce vibrations at the handle-bar
          reducing oeprator fatique and increasing ergonomics. Containment Skirt
          standard to prevent solution or dust spread while in operation.{" "}
        </description>
      </Helmet>
      <MainLayout>
        <ProductLayout>
          <div id="products-layout">
            <ImagePicker
              border="none"
              image1Large={Image1Large}
              mobileLink="#demo-section"
              mobileTitle='20" Orbital Floor Machine'
            />
            <div>
              <ProductDescription
                productTitle='20" Orbital Floor Machine'
                subHeading="AC Powered"
                modelNum="TF20R"
                productDescription='Thoroughly clean a variety of different floor surfaces with the ORBITZ 20" Floor Machine.  Pads are installed and removed without tools for easy application switching.  Heavy-duty rolling wheels allow for ease of transport.  Heavy-duty isolators reduce vibrations at the handle-bar reducing oeprator fatique and increasing ergonomics.  Containment Skirt standard to prevent solution or dust spread while in operation.'
                link="#demo-section"
                list1="Constructed robustly — steel frame construction and durable components "
                list2="Removeable Deck Weights for Transportation or Application Needs"
                list3="Optional HEPA Onboard Vacuum to capture dust"
                list4="Easy to operate trigger handle assembly"
                listStyle="none"
                // list5="Clean any hour of the day with approved sound levels < 67 dBA"
                // list6="Increase productivity with over 2 hours of run time between dump and refill"
                // list7="Off-the-shelf toggles, switches, gauges, and mechanical levers to allow easy operator training and potential repairs"
                brochure="../../ORBITZ-SALES-SHEET.pdf"
                tech="../../tl-orbitz-tech-specs-v21263.pdf"
                parts="../../TL-COMING-SOON.pdf"
                operator="../../TL-COMING-SOON.pdf"
                hood="../../ORBITZ-SALES-SHEET.pdf"
                displayHood="none"
                showPM="none"
              />
            </div>
          </div>
          <div id="demo-section">
            <IndexSection3 title="Request a Free Demo at your Facility" />
          </div>
        </ProductLayout>
      </MainLayout>
    </>
  )
}

export default Xs17PaCompactPage
